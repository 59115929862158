import { OrganizationFeaturesEnum, OrganizationPermissionEnum } from "@vegga-statics/enums";
import { environment } from "../environments/environment";
import { IDropdownOption, INavbarOption } from "./interfaces/navbarOptions";
import { OrganizationType } from "./enums/OrganizationType";

export const navbarOptions: INavbarOption[] = [
  {
    icon: "home",
    label: "menu.home",
    linkTo: "/home",
    flag: environment.FLAGS.DISPLAY_HOME,
  },
  {
    icon: "customer",
    label: "menu.customers",
    linkTo: "/customers",
    flag: environment.FLAGS.DISPLAY_CUSTOMERS,
    organizationTypesExcluded: [OrganizationType.FARMER],
  },
  {
    icon: "envelope",
    label: "menu.messages",
    linkTo: "/",
    flag: environment.FLAGS.DISPLAY_MESSAGES,
  },
  {
    icon: "hand-holding-droplet",
    iconKind: "light",
    label: "menu.irrigators",
    linkTo: "irrigation-community/irrigators",
    flag: environment.FLAGS.DISPLAY_IRRIGATION_COMMUNITY_SUBMODULE_IRRIGATORS,
    feature:
      OrganizationFeaturesEnum.IRRIGATION_COMMUNITY_SUBMODULE_IRRIGATORS_ENABLED as keyof typeof OrganizationFeaturesEnum,
    permission:
      OrganizationPermissionEnum.ACCESS_IRRIGATION_COMMUNITY_SUBMODULE_IRRIGATORS as keyof typeof OrganizationPermissionEnum,
  },
  {
    icon: "people-group",
    iconKind: "solid",
    label: "menu.settings",
    linkTo: "/organization",
    flag: environment.FLAGS.DISPLAY_CONFIGURATION,
    permission:
      OrganizationPermissionEnum.ACCESS_YOUR_ORGANIZATION as keyof typeof OrganizationPermissionEnum,
  },
  {
    icon: "bell",
    label: "",
    linkTo: "",
    flag: environment.FLAGS.DISPLAY_NOTIFICATIONS,
  },
];

export const dropdownItems: IDropdownOption[] = [
  {
    label: "menu.profile",
    linkTo: "/profile",
    flag: environment.FLAGS.DISPLAY_PROFILE,
    isLink: true,
    features: OrganizationFeaturesEnum.ORGANIZATION_USER_EDIT_PROFILE_ALLOWED,
  },
  {
    label: "menu.about",
    linkTo: "/about-vegga",
    flag: environment.FLAGS.DISPLAY_ABOUT_VEGGA,
    isLink: true,
  },
  {
    label: "menu.help",
    linkTo: "/help",
    flag: environment.FLAGS.DISPLAY_HELP,
    isLink: true,
  },
  {
    label: "menu.log-out",
    flag: environment.FLAGS.DISPLAY_LOG_OUT,
  },
];
